import React from 'react';
import './Loader.css';
import loadingImage from '../../assets/loadingImage.png'; // Adjust the path based on your structure

const Loader = () => (
    <div className="loader-overlay">
        <div className="loader">
            <img src={loadingImage} alt="Loading..." />
            <p className="loading-text">Loading, please wait...</p>
        </div>
    </div>
);

export default Loader;
