import React, { useState,useEffect } from "react";
import { Form, Button, Card, Dropdown, Container, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import { Formik,Field,ErrorMessage } from "formik";
import './PolicyForm.css';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import { apiRequest } from "../../../services/api";
import { useToast } from "../../../contexts/ToastContext";
import { Next } from "react-bootstrap/esm/PageItem";
import { encryptData } from '../../Common/encryptionUtils';

// StepProgress component with rounded tick marks
const StepProgress = ({ currentStep }) => {
  const steps = ["Policies", "Rules", "Save"];

  return (
    <div className="mb-4 position-relative">
      {/* Steps */}
      <div className="d-flex justify-content-between stepBarDiv position-relative w-100">
        {steps.map((step, index) => (
          <div
            key={`step-${index}`}
            className="text-center stepBar"
            style={{ flex: 1, position: "relative" }}
          >
            <div
              className={`step-circle ${index <= currentStep ? "completed" : ""}`}
              style={{
                width: "30px", // Circle size
                height: "30px", // Circle size
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto", // Center the circle
                border: "2px solid",
                borderColor: index <= currentStep ? "#007bff" : "#dcdcdc",
                backgroundColor: index <= currentStep ? "#007bff" : "transparent",
                color: index <= currentStep ? "#fff" : "#dcdcdc",
              }}
            >
              {index <= currentStep ? "✔" : ""}
            </div>
            <span
              className={`mt-2 d-block stepTitle ${
                index <= currentStep ? "text-primary" : "text-muted"
              }`}
              style={{ fontSize: "12px" }}
            >
              {step}
            </span>
          </div>
        ))}
      </div>

      {/* Progress Bar Segments */}
      <div className="stepBarLine"
        style={{
          position: "absolute",
          top: "13px", // Position the bars below the steps
          left: 0,
          right: 0,
        }}
      >
        <div className="d-flex justify-content-between align-items-center w-100 stepbarwidth">
          {steps.slice(0, -1).map((_, index) => (
            <div
              key={`bar-${index}`}
              className={`bar-${index}`}
              style={{
                flex: 1,
                height: "2px",
                backgroundColor: index < currentStep ? "#007bff" : "#dcdcdc",
                margin: "0 10px", // Space between bars
                borderRadius: "4px",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const PoliciesStep = ({ onNext,policyData ,policyFormbackButton}) => {
  // Get tomorrow's date in `yyyy-mm-dd` format

  const getPolicyDetails = (values) => {
    localStorage.setItem('policyName',values.policyName);
    localStorage.setItem('policyDate',values.policyDate);
  }
  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 2); // Add 1 day
    return tomorrow.toISOString().split("T")[0]; // Convert to yyyy-mm-dd
  };

  const policyBackButton = () =>{
    console.log('sds-->',)
    policyFormbackButton();
  }
  console.log('policyData121--->',policyData);

    // Create a Date object
    const date = new Date(policyData.start_date_time);
  
    // Format the date as YYYY-MM-DD
    const formattedDate = date.toISOString().split('T')[0];

  const initialValues = {
    policyName: localStorage.getItem("policyName") !== null ? localStorage.getItem("policyName") : policyData.policy_name,
    policyDate: localStorage.getItem("policyDate") !== null ? localStorage.getItem("policyDate") : formattedDate
  };
  console.log('initialValues--->',initialValues);
  const validationSchema = Yup.object({
    policyName: Yup.string()
      .trim()
      .required("Policy name is required."),
    // policyDate: Yup.date()
    //   .min(getTomorrowDate(), "Policy date must be in the future.")
    //   .required("Policy date is required."),
  });

  const [ruleType,setRuleType] = useState([]);

  const [rules, setRules] = useState(() => {
    // Retrieve rules from local storage or initialize with a default rule
    const savedRules = localStorage.getItem("rules");
    return savedRules
      ? JSON.parse(savedRules)
      : [
          {
            rule_name: "",
            workDuration: "1h",
            workDurationMins: "0m",
            breakDuration: "",
            breakType: localStorage.getItem("breakType"),
            notificationType: localStorage.getItem("notificationType"),
            rules_and_alerts_uitext_id: null,
            rules_and_alerts_breaktype_id : null,
            reminderInterval: "5",
            reminderCount: "3",
            restBreak1: true,
            acceptBreak: true,
            rejectBreak: true,
            addViolation : true,
            penalty: false,
            violation: {
              violation_rule_name : "",
              workDuration1: "1h",  // Default value
              workDurationMins1: "15m",      // Default value
              notificationType1: "send notification to the manager & admin",    // Initialize violation message if needed
              violationDate1: null,     // Store violation date if needed
              violation_rules_and_alerts_uitext_id : "2"
            },
          },
        ];
  });


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onNext(values); // Pass the validated form values to the next step
        getPolicyDetails(values);
      }}
    >
      {({ handleSubmit }) => (
        <Card className="shadow-sm p-4" style={{ borderRadius: "10px" }}>
          <Card.Body>
          <div className="row">
              <div className="col-6">
              <i
                className="fa fa-long-arrow-left backIcon"
                aria-hidden="true"
                onClick={() => policyBackButton()}
                style={{ cursor: "pointer" }}
              ></i>
              </div>
              <div className="col-6">
              </div>
            </div>
            <h4 className="text-primary mb-4 text-center policyFormTitle">Policies</h4>
            <Form noValidate onSubmit={handleSubmit} className="PolicyCreateForm">
            <div className="row mb-2 loginInputDiv policyValue">
              <div className="col-md-12"> 
              <div className="form-group mb-4">
                <Field
                  type="text"
                  name="policyName"
                  disabled
                  id="policyName"
                  className="login-form-input policy-input"
                />
                <label htmlFor="policyName" className="form-label">
                  Policy Name <span className="text-danger">*</span>
                </label>
              </div>
              </div>
              <ErrorMessage
                  name="policyName"
                  component="div"
                  className="text-danger policy-error mb-2"
                />
              <div className="col-md-12 mt-4"> 
              <div className="form-group mb-3">
                <Field
                  type="date"
                  name="policyDate"
                  id="policyDate"
                  disabled
                  className="login-form-input policy-input"
                  min={getTomorrowDate()} // Set minimum date to tomorrow
                />
                <label htmlFor="policyDate" className="form-label">
                  Policy Date <span className="text-danger">*</span>
                </label>
                <ErrorMessage
                  name="policyDate"
                  component="div"
                  className="text-danger"
                />
              </div>
              </div>
              </div>
              {/* <div className="d-flex justify-content-between mt-4">
                <Button variant="light" disabled>
                  Previous
                </Button>
                <Button variant="primary" >
                  Next
                </Button>
              </div> */}

              <div className="d-flex justify-content-end mt-5">
                {/* <Button className="previousBtn" variant="light" disabled>
                  Previous
                </Button> */}
                <Button className="previousBtn" type="submit" variant="primary">
                  Next
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};

const RulesStep = ({ onPrevious, onNext,policyData }) => {
  const [breakType,setBreakType] = useState([]);
  const [rules, setRules] = useState(() => {
    // Retrieve rules from local storage or initialize with a default rule
    const savedRules = localStorage.getItem("rules");
    return savedRules
      ? JSON.parse(savedRules)
      : [
          {
            restBreak1: true,
            acceptBreak: true,
            rejectBreak: true,
            addViolation : true,
            penalty: false,
            violation: {
              violation_rule_name : "",
              workDuration1: "",  // Default value
              workDurationMins1: "",      // Default value
              notificationType1: "",    // Initialize violation message if needed
              violationDate1: null,     // Store violation date if needed
              violation_rules_and_alerts_uitext_id : ""
            },
          },
        ];
  });

  const [showModal, setShowModal] = useState(false);
  const [ruleType,setRuleType] = useState([]);
  const [ruleTypeViolation,setRuleTypeViolation] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [breakMinTime, setBreakMinTime] = useState({});
  const [violationHours, setViolationHours] = useState({});
  const [violationMins, setViolationMins] = useState({});
  // console.log('rules--->',rules)
  // Save rules to local storage whenever they are updated
  useEffect(() => {
    console.log('rulesIntial--->',rules)
    if(rules.rule_name !== ""){
    //   localStorage.setItem("rules", JSON.stringify(rules));
    }
  }, [rules]);
  // console.log('rules--->',rules)
    // Save rules to local storage whenever they are updated
    useEffect(() => {
      const getRulesUiText = async () => {
        const authToken = localStorage.getItem('authToken');
        const headers = {
          "Authorization" : `Bearer ${authToken}`
        }
        const rulesResponse = await apiRequest('web_api/admin/getRulesUiText','GET',null,headers)
        // console.log('rulesResponse--->',rulesResponse)
        if(rulesResponse?.data?.status_code == 200){
          const rule_list = rulesResponse.data?.data?.get_rules_and_alerts_uitext;
          // console.log('rulesResponse--->',rule_list)
          let rule_list_violation = rule_list.filter(item => item.text_to_be_displayed !== "send notification to the user");
          setRuleTypeViolation(rule_list_violation);
          setRuleType(rule_list);          
        }
      }

      const getBreakType = async () => {
        const authToken = localStorage.getItem('authToken');
        const headers = {
          "Authorization" : `Bearer ${authToken}`
        }
        const rulesResponse = await apiRequest('web_api/admin/getBreakType','GET',null,headers)
        // console.log('rulesResponse--->',rulesResponse)
        if(rulesResponse?.data?.status_code == 200){
          let break_list = rulesResponse.data?.data?.get_staff_break_type;
          // console.log('BreakTypeResponse--->',break_list)
          break_list = break_list.filter(item => item.rules_and_alerts_breaktype !== "Violation");
          setBreakType(break_list);
        }
      }

      const fetchRulesByPolicyId = async () => {
        const authToken = localStorage.getItem('authToken');
        try {
          const headers = {
            "Authorization": `Bearer ${authToken}`
          };
          const encryptedPolicyId = encryptData(policyData.rules_and_alerts_policy_id.toString());
          const rulesResponse = await apiRequest(
            `web_api/admin/getRuleDataByPolicyId?policy_id=${encryptedPolicyId}`,
            'GET',
            null,
            headers
          );
      
          // console.log('policyCreateResponse--->', policyCreateResponse);
      
          if (rulesResponse?.data?.status_code === 200) {
            const rule_details = rulesResponse?.data?.data;
            console.log('rule_details--->', rule_details);
            console.log('ruleType--->',ruleType);
            const transformedData = rule_details.map(rule => {

              const rule_list = JSON.parse(localStorage.getItem("rule_list"));
                const matchedRuleType = rule_list.find(
                    detail => detail.rules_and_alerts_uitext_id === rule.rules_and_alerts_uitext_id
                );
                console.log('matchedRuleType-->',matchedRuleType)
                const break_list = JSON.parse(localStorage.getItem("break_list"));
                const matchedBreakType = break_list.find(
                    detail => detail.rules_and_alerts_breaktype_id === rule.rules_and_alerts_breaktype_id
                );
                const violation = rule.violation;
                let violationWorkDuration1;
                let violationWorkMins1;
                let matchedRuleTypeviolation;
                if(violation !== null){
                  violationWorkDuration1 = `${parseInt(violation.minimum_hours.split(':')[0])}h`
                  violationWorkMins1 = `${parseInt(violation.minimum_hours.split(':')[1])}m`
                  matchedRuleTypeviolation = rule_list.find(
                    detail => detail.rules_and_alerts_uitext_id === violation.rules_and_alerts_uitext_id
                  );
                }
                console.log('matchedRuleTypeviolation--->',matchedRuleTypeviolation)
                return {
                ...rule,
                workDuration: `${parseInt(rule.minimum_hours.split(':')[0])}h`,
                workDurationMins: `${parseInt(rule.minimum_hours.split(':')[1])}m`,
                breakDuration: `${parseInt(rule.break_duration_minutes.split(':')[1])}`,
                notificationType: matchedRuleType ? matchedRuleType.text_to_be_displayed : "Unknown",
                breakType: matchedBreakType ? matchedBreakType.rules_and_alerts_breaktype : "Unknown",
                reminderInterval : `${parseInt(rule.notification_expiration_duration.split(':')[1])}`,
                violation: {
                  ...violation,
                  workDuration1: violationWorkDuration1,
                  workDurationMins1 : violationWorkMins1,
                  notificationType1 : matchedRuleTypeviolation ? matchedRuleTypeviolation.text_to_be_displayed : "Unknown",
                },
            
                };
            });
            console.log('transformedData--->',transformedData)
            setRules(transformedData);

          } else {
            // Handle API response with errors or non-200 status codes
            const errorMessage = rulesResponse?.data?.message || 'Something went wrong.';
            toast.error(`${errorMessage}`, {
              position: 'top-right',
              autoClose: 4000,
            });
          }
        } catch (error) {
          // Handle network or unexpected errors
          // console.error('Error while creating policy:', error);
          // toast.error('Failed to create policy. Please try again later.', {
          //   position: 'top-right',
          //   autoClose: 4000,
          // });
        }
      };
      
      fetchRulesByPolicyId();


      getRulesUiText();
      getBreakType();
    }, []);
  // console.log('breakMinTime--->',breakMinTime)
  // Helper functions for dropdown options
  const generateHourOptions = (index) =>
    [...Array(24-1)].map((_, hour) => (
      <Dropdown.Item 
        className="dropdownList"
        key={hour}
        onClick={() => handleChange("workDuration", `${hour + 1}h`, index)}
      >
        {hour + 1}h
      </Dropdown.Item>
    ));

    // const generateHourOptions1 = (index) =>
    // [...Array(24)].map((_, hour) => (
    //   <Dropdown.Item
    //     className="dropdownList"
    //     key={hour}
    //     onClick={() => handleChange("workDuration1", `${hour + 1}h`, index, "workDuration1")} // Update violation.workDuration
    //   >
    //     {hour + 1}h
    //   </Dropdown.Item>
    // ));


    const generateHourOptions1 = (index) => {
      const startHour = violationHours[index] !== undefined ? violationHours[index] : 1;
      return [...Array(24 - startHour)] // Create an array starting from startHour up to 24
        .map((_, hour) => hour + startHour) // Generate hours starting from startHour
        .map(hour => (
          <Dropdown.Item
            className="dropdownList"
            key={hour}
            onClick={() => handleChange("workDuration1", `${hour}h`, index, "workDuration1")}
          >
            {hour}h
          </Dropdown.Item>
        ));
    };
    
    


    // const generateMinuteOptions1 = (index) =>
    //   [...Array(60)].map((_, minute) => (
    //     <Dropdown.Item
    //       className="dropdownList"
    //       key={minute}
    //       onClick={() => handleChange("workDurationMins1", `${minute}m`, index, "workDurationMins1")} // Update violation.workDurationMins1
    //     >
    //       {minute}m
    //     </Dropdown.Item>
    //   ));


      // const generateMinuteOptions1 = (index) =>
      //   [...Array(13)].map((_, i) => {
      //     const minute = i * 5; // Generate multiples of 5 (0, 5, 10, ..., 60)
      //     return (
      //       <Dropdown.Item
      //         className="dropdownList"
      //         key={minute}
      //         onClick={() =>
      //           handleChange("workDurationMins1", `${minute}m`, index, "workDurationMins1")
      //         }
      //       >
      //         {minute}m
      //       </Dropdown.Item>
      //     );
      // });

      const generateMinuteOptions1 = (index) => {
        const startMinute = violationMins[index] !== undefined ? violationMins[index] : 15; // Default to 0 if undefined
        return [...Array(13 - Math.floor(startMinute / 5))] // Adjust array size based on startMinute
          .map((_, i) => i * 5 + startMinute) // Generate multiples of 5 starting from startMinute
          .map(minute => (
            <Dropdown.Item
              className="dropdownList"
              key={minute}
              onClick={() =>
                handleChange("workDurationMins1", `${minute}m`, index, "workDurationMins1")
              }
            >
            {minute === 60 ? `${minute - 1}m` : `${minute}m`} 
            </Dropdown.Item>
          ));
      };
      
      
    

    // const generateMinuteOptions = (type, index) => 
    //   [...Array(60)]
    //     .map((_, minute) => minute) // Generate an array of numbers from 0 to 59
    //     .filter(minute => type === "breakDuration" ? minute >= minimumMinutes : true) // Start from 10 for "breakDuration"
    //     .map(minute => (
    //   <Dropdown.Item
    //     className="dropdownList"
    //     key={minute}
    //     onClick={() =>
    //       handleChange(type, `${minute}${type !== "breakDuration" ? "m" : ""}`, index)
    //     }
    //   >
    //     {minute}m
    //   </Dropdown.Item>
    // ));

    
    // const generateMinuteOptions = (type, index) => {
    //   const minimumMinutes = breakMinTime[index] !== undefined ? breakMinTime[index] : 10;
    //   // console.log('minimumMinutes--->',minimumMinutes)
    //   return [...Array(60)]
    //     .map((_, minute) => minute) // Generate minutes from 0 to 59
    //     .filter(minute => type === "breakDuration" ? minute >= minimumMinutes : type === "reminderInterval" ? minute >= 1 : true) // Filter minutes based on minimum_minutes
    //     .map(minute => (
    //       <Dropdown.Item
    //         className="dropdownList"
    //         key={minute}
    //         onClick={() =>
    //           handleChange(type, `${minute}${type !== "breakDuration" ? "m" : ""}`, index)
    //         }
    //       >
    //         {minute}m
    //       </Dropdown.Item>
    //     ));
    // };

    
    const generateMinuteOptions = (type, index) => {
      const minimumMinutes = breakMinTime[index] !== undefined ? breakMinTime[index] : 10;
    
      return [...Array(13)] // Array of 13 items (0m to 60m in 5-minute increments)
        .map((_, i) => i * 5) // Generate multiples of 5 (0, 5, 10, ...)
        .filter(minute =>
          type === "breakDuration"
            ? minute >= minimumMinutes
            : type === "reminderInterval"
            ? minute >= 1
            : true
        ) // Apply filters for minimum minutes
        .map(minute => (
          <Dropdown.Item
            className="dropdownList"
            key={minute}
            onClick={() =>
              handleChange(type, `${minute}${type !== "breakDuration" ? "m" : ""}`, index)
            }
          >
            {minute === 60 ? `${minute - 1}m` : `${minute}m`} 
            {/* {minute}m */}
          </Dropdown.Item>
        ));
    };

    const generateMinuteOptionsForExprire = (type, index) => {
      const minimumMinutes = breakMinTime[index] !== undefined ? breakMinTime[index] : 10;
    
      return [...Array(13)] // Array of 13 items (0m to 60m in 5-minute increments)
        .map((_, i) => i * 5) // Generate multiples of 5 (0, 5, 10, ...)
        .filter(minute =>
          type === "breakDuration"
            ? minute >= minimumMinutes
            : type === "reminderInterval"
            ? minute >= 1
            : true
        ) // Apply filters for minimum minutes
        .map(minute => (
          <Dropdown.Item
            className="dropdownList"
            key={minute}
            onClick={() =>
              handleChange(type, `${minute}`, index)
            }
          >
            {minute === 60 ? `${minute - 1}m` : `${minute}m`} 
            {/* {minute}m */}
          </Dropdown.Item>
        ));
    };

  const handleChange = (key, value, index, violationKey = null) => {
    setRules((prevRules) => {
      const updatedRules = [...prevRules];
  
      // If violationKey is provided, update the violation object
      if (violationKey) {
        if(violationKey === "violation_rule_name"){
          setViolationErrorFields((prevErrors) => prevErrors.filter((i) => i !== index));
        }
        if (key === "notificationType1") {
          updatedRules[index].violation = {
            ...updatedRules[index].violation,
            [key]: value.text,  // Update the displayed text in violation
            [`${key}Id`]: value.id,  // Update the associated ID in violation
            [`violation_rules_and_alerts_uitext_id`]: value.id, 
          };
        } else {
          updatedRules[index].violation[violationKey] = value;  // For other violation properties
        }

      } else {
        // if(key === "addViolation"){
        //   console.log('updatedRules[index].addViolation-->',updatedRules[index].addViolation)
        //   if(updatedRules[index].addViolation === true){
        //     updatedRules[index].violation = {};
        //     console.log('violation object',updatedRules[index].violation);
        //   }
        // }
        if (key === "workDuration") {
          console.log('workDuration-->',value)
          const workDuration =
          key === "workDuration" ? value : updatedRules[index].workDuration;
          const workDurationMins =
          key === "workDurationMins" ? value : updatedRules[index].workDurationMins;
          const workDurationHoursValue = parseInt(workDuration.replace("h", ""), 10) || 0;
          const workDurationMinsValue = parseInt(workDurationMins.replace("m", ""), 10) || 0;

          updatedRules[index] = {
            ...updatedRules[index],
            workDuration: value,
            workDurationMins: '0m',
            violation: {
              ...updatedRules[index].violation,
              workDuration1: value, // Sync violation_rule_name with rule_name
            }
          };
          setViolationHours((prev) => ({ ...prev, [index]: workDurationHoursValue }));
          // if(workDurationMinsValue > 45){
          //   console.log('65above');
          //   console.log(`65above,${workDurationHoursValue + 1}h`);
          //   console.log(`65above,${(workDurationMinsValue + 15) - 60}m`);
          //   // updatedRules[index] = {
          //   //   violation: {
          //   //     ...updatedRules[index].violation,
          //   //     workDuration1: `${workDurationHoursValue + 1}h`,
          //   //     workDurationMins1: `${(workDurationMinsValue + 15) - 60}m`, // Sync violation_rule_name with rule_name
          //   //   }
          //   // };
          // }
          // else{
            updatedRules[index] = {
              ...updatedRules[index],
              violation: {
                ...updatedRules[index].violation,
                workDurationMins1: `${15}m`, // Sync violation_rule_name with rule_name
              }
            };
            setViolationMins((prev) => ({ ...prev, [index]: (0 + 15) }));
          // }
        }
        if (key === "workDurationMins") {
          const workDuration =
          key === "workDuration" ? value : updatedRules[index].workDuration;
          const workDurationMins =
          key === "workDurationMins" ? value : updatedRules[index].workDurationMins;
          console.log('workDurationMinsaaa-->',workDurationMins)
          const workDurationHoursValue = parseInt(workDuration.replace("h", ""), 10) || 0;
          const workDurationMinsValue = parseInt(workDurationMins.replace("m", ""), 10) || 0;
          console.log('hoursWorkDurationMins-->',workDurationMinsValue)
          if(workDurationMinsValue > 45){
            console.log('65above')
            updatedRules[index] = {
              ...updatedRules[index],
              workDurationMins: value,  // Update rule name
              violation: {
                ...updatedRules[index].violation,
                workDuration1: `${workDurationHoursValue + 1}h`,
                workDurationMins1: `${(workDurationMinsValue + 15) - 60}m`, // Sync violation_rule_name with rule_name
              }
            };
            setViolationMins((prev) => ({ ...prev, [index]: ((workDurationMinsValue + 15) - 60) }));
          }
          else{
            updatedRules[index] = {
              ...updatedRules[index],
              workDurationMins: value,  // Update rule name
              violation: {
                ...updatedRules[index].violation,
                workDurationMins1: `${workDurationMinsValue + 15}m`, // Sync violation_rule_name with rule_name
              }
            };
            setViolationMins((prev) => ({ ...prev, [index]: (workDurationMinsValue + 15) }));
          }
        }
        if(key === "rule_name"){
          const sanitizedValue = value
          .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
          .trim();
          updatedRules[index] = {
            ...updatedRules[index],
            rule_name: sanitizedValue,  // Update rule name
            violation: {
              ...updatedRules[index].violation,
              violation_rule_name: sanitizedValue, // Sync violation_rule_name with rule_name
            }
          };
          setErrorFields((prevErrors) => prevErrors.filter((i) => i !== index));
          setViolationErrorFields((prevErrors) => prevErrors.filter((i) => i !== index));
        }
        // Recalculate continuous_hours whenever workDuration or workDurationMins changes
        // if (key === "workDuration" || key === "workDurationMins") {
          const workDuration =
            key === "workDuration" ? value : updatedRules[index].workDuration;
          const workDurationMins =
            key === "workDurationMins" ? value : updatedRules[index].workDurationMins;
  
          const hours = parseInt(workDuration.replace("h", ""), 10) || 0;
          const minutes = parseInt(workDurationMins.replace("m", ""), 10) || 0;
  
          const totalMinutes = hours * 60 + minutes;
          const formattedHours = Math.floor(totalMinutes / 60)
            .toString()
            .padStart(2, "0");
          const formattedMinutes = (totalMinutes % 60).toString().padStart(2, "0");
  
          updatedRules[index].continuous_hours = `${formattedHours}:${formattedMinutes}:00`;
        // }
  
        // Handle breakType change
        if (key === "breakType") {
          const restBreak = breakType.find(
            (item) => item.rules_and_alerts_breaktype === value.text
          );
  
          // Extract the minimum_minutes
          const minimumMinutes = restBreak ? restBreak.minimum_minutes : "Not found";
          updatedRules[index].breakDuration = minimumMinutes;
          setBreakMinTime((prev) => ({ ...prev, [index]: minimumMinutes }));
          // console.log('updatedRules[index].breakType-->', minimumMinutes);
        }
  
        // Handle breakDuration formatting
        const breakDuration =
          key === "breakDuration" ? value : updatedRules[index].breakDuration;
  
        const formattedHours1 = "00"; // Hours will always be "00"
        const formattedMinutes1 = breakDuration.toString().padStart(2, "0"); // Calculate minutes and format to 2 digits
  
        updatedRules[index].total_break_minutes = `${formattedHours1}:${formattedMinutes1}:00`;
  
        // Update other properties as needed
        updatedRules[index].waivable_status = "mandatory";
        updatedRules[index].max_waivable_hours = "";
        updatedRules[index].required_prior_break = "";
        updatedRules[index].waiver_conditions = "";
        updatedRules[index].violation_notification = "true";
  
        // Handle reminderInterval formatting
        const reminderInterval =
          key === "reminderInterval" ? value : updatedRules[index].reminderInterval;
        console.log('reminderInterval--->',reminderInterval)
        const formattedHours2 = "00"; // Hours will always be "00"
        const formattedMinutes2 = reminderInterval.toString().padStart(2, "0"); // Format minutes
        console.log('formattedMinutes2--->',formattedMinutes2)
        updatedRules[index].notification_expiration_duration = `${formattedHours2}:${formattedMinutes2}:00`;
      }
  
      // If the key is notificationType or breakType, update the corresponding property
      if (key === "notificationType" || key === "breakType") {
        updatedRules[index] = {
          ...updatedRules[index],
          [key]: value.text, // Update the displayed text
          [`${key}Id`]: value.id, // Update the associated ID
        };
      } else {
        updatedRules[index] = {
          ...updatedRules[index],
          [key]: value,
        };
      }

      if (key === "notificationType") {
        updatedRules[index] = {
          ...updatedRules[index],
          [key]: value.text, // Update the displayed text
          [`rules_and_alerts_uitext_id`]: value.id, // Update the associated ID
        };
      }

      if (key === "breakType") {
        updatedRules[index] = {
          ...updatedRules[index],
          [key]: value.text, // Update the displayed text
          [`rules_and_alerts_breaktype_id`]: value.id, // Update the associated ID
        };
      }

      // This violation section hours and minuties

      // if (key === "workDuration1" || key === "workDurationMins1") {

      console.log('updatedRules[index].addViolation-->',updatedRules[index].addViolation)
      if(updatedRules[index].addViolation){
            const workDuration1 =
            key === "workDuration1" ? value : updatedRules[index].violation.workDuration1;
          const workDurationMins1 =
            key === "workDurationMins1" ? value : updatedRules[index].violation.workDurationMins1;

          const hours1 = parseInt(workDuration1.replace("h", ""), 10) || 0;
          const minutes1 = parseInt(workDurationMins1.replace("m", ""), 10) || 0;
          console.log('minutes1-->',minutes1)

          const totalMinutes1 = hours1 * 60 + minutes1;
          const formattedHours1 = Math.floor(totalMinutes1 / 60)
            .toString()
            .padStart(2, "0");
          const formattedMinutes1 = (totalMinutes1 % 60).toString().padStart(2, "0");
          console.log('formattedMinutes1-->',formattedMinutes1)

          updatedRules[index].violation.violation_continuous_hours = `${formattedHours1}:${formattedMinutes1}:00`;
    // }
      }else{
        updatedRules[index].violation = {
          violation_rule_name : "",
          workDuration1: "1h",  // Default value
          workDurationMins1: "15m",      // Default value
          notificationType1: "send notification to the manager & admin",    // Initialize violation message if needed
          violationDate1: null,     // Store violation date if needed
          violation_rules_and_alerts_uitext_id : "2"
        };
      }
  
      return updatedRules;
    });
  };
  
  // console.log('ruleDetails--->',rules[0])
  // Delete a rule
  const deleteRule = (index) => {
    const updatedRules = rules.filter((_, i) => i !== index);
    setRules(updatedRules);
  };

  // Modal handling
  const handleShow = (index) => {
    setCurrentIndex(index);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentIndex(null);
  };

  const handleConfirm = () => {
    toast.success("Rule deleted successfully!", {
      position: "top-right",
      autoClose: 4000,
    });
    deleteRule(currentIndex);
    handleClose();
  };

  const numberToWords = (num) => {
    const words = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    return words[num] || num; // Fallback to the number itself if out of range
  };

  const [error, setError] = useState(null);
  const [errorFields, setErrorFields] = useState([]);
  const [violationErrorFields, setViolationErrorFields] = useState([]);

  const validateRules = () => {
    const errors = [];
    const errorViolation = [];
    rules.forEach((rule, index) => {
      if (!rule.rule_name) {
        errors.push(index); // track the rule index with empty rule name
      }
      console.log('rule.addViolation-->',rule.addViolation)
      if(rule.addViolation){
        if (!rule.violation.violation_rule_name) {
          console.log('rule.addViolation-->11',rule.addViolation)
          errorViolation.push(index); // track the rule index with empty rule name
        }
      }
    });
    setErrorFields(errors);
    setViolationErrorFields(errorViolation);
    return errors.length === 0 && errorViolation.length === 0;
  };

  console.log('errorFields-->',errorFields);
    const onNexts = () => {
    if (!validateRules()) {
      console.log('Proceed to the next step11');
      return;
    }
    onNext();
    console.log('Proceed to the next step');
  };

  return (
    <Card className="shadow-sm p-4" style={{ borderRadius: "10px" }}>
      <Card.Body className="policyFormCard">
        <h4 className="text-primary mb-4 text-center policyFormTitle">Rules</h4>
        <div className="row addNewRuleDiv">
          <div className="col-md-4 mb-3">
            {/* <button className="btn addNewRuleBtn" onClick={addNewRule}>
              <i className="fa fa-plus" aria-hidden="true"></i> New Rule
            </button> */}
          </div>
        </div>

        {/* Map through each rule and display it */}
        {rules.map((rule, index) => (
          <div key={index} className="mb-4 ruleCreationDiv">
            <div className="d-flex justify-content-start align-items-center">
              <div className="col-md-1">
                <Form.Check
                  type="switch"
                  id={`rest-break-1-${index}`}
                  checked={true}
                  disabled
                  onChange={(e) => handleChange('restBreak1', e.target.checked, index)}
                />
              </div>
              <div className="col-md-4 rule_title">
                <input
                  type="text"
                  className="inputWithBottomBorder"
                  placeholder="Rule name"
                  value={rule.rule_name}
                  disabled
                  autoFocus
                  onChange={(e) => handleChange('rule_name', e.target.value, index)}
                />
              </div>
              <div className="col-md-3">
              </div>
              {index !== 0 && 
              <div className="col-md-4 deleteRuleDiv">
                <button
                  className="btn deleteRuleBtn"
                  onClick={() => handleShow(index)}
                  disabled
                  // onClick={() => deleteRule(index)}
                  >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>}
            </div>
            {/* Show error message under the rule name input field */}
            {errorFields.includes(index) && (
              <div className="text-danger register-error rule_validation">Rule name is required</div>
            )}
            <div className="mt-3 ruleDesc">
              If the staff has been at work for{" "}
              <Dropdown className="hourDiv">
                <Dropdown.Toggle variant="light" size="sm" disabled className="dropdownToggle durationSelectedValue">
                  {rule.workDuration}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto", minWidth: '80px' }}>
                  {generateHourOptions(index)}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="hourDiv">
                <Dropdown.Toggle variant="light" size="sm" disabled className="dropdownToggle durationSelectedValue">
                  {rule.workDurationMins}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto", minWidth: '80px' }}>
                  {generateMinuteOptions('workDurationMins',index)}
                </Dropdown.Menu>
              </Dropdown>
              {/* <Dropdown className="hourDiv">
                <Dropdown.Toggle variant="light" size="sm" className="dropdownToggle durationSelectedValue">
                  {rule.notificationType}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto", minWidth: '80px' }}>
                  <Dropdown.Item className="dropdownList" onClick={() => handleChange('notificationType', "send notification to the manager & admin", index)}>
                    send notification to the manager & admin
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdownList" onClick={() => handleChange('notificationType', "send notification to the user", index)}>
                    send notification to the user
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdownList" onClick={() => handleChange('notificationType', "force checkout", index)}>
                    force checkout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
              <Dropdown className="hourDiv">
                  <Dropdown.Toggle variant="light" size="sm" disabled className="dropdownToggle durationSelectedValue">
                      {rule.notificationType}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                  {ruleType.map((item) => (
                    <Dropdown.Item 
                    className="dropdownList" onClick={() => handleChange('notificationType',  { text: item.text_to_be_displayed, id: item.rules_and_alerts_uitext_id }, index)}
                      key={item.rules_and_alerts_uitext_id}
                    >
                      {item.text_to_be_displayed}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {rule.notificationType !== "force checkout" ?
              (
                <>
                to take a{" "}
                <Dropdown className="hourDiv">
                    <Dropdown.Toggle variant="light" size="sm" disabled className="dropdownToggle durationSelectedValue">
                        {rule.breakType}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    {breakType.map((item) => (
                      <Dropdown.Item 
                      className="dropdownList" onClick={() => handleChange('breakType',  { text: item.rules_and_alerts_breaktype, id: item.rules_and_alerts_breaktype_id }, index)}
                        key={item.rules_and_alerts_breaktype_id}
                      >
                        {item.rules_and_alerts_breaktype}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                </>
              ) :
              (
                <>
                then staff will be checked out {" "}
                </>
              )  
            }
              {/* <Dropdown className="hourDiv">
                <Dropdown.Toggle variant="light" size="sm" className="dropdownToggle durationSelectedValue">
                  {rule.breakType}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto", minWidth: '80px' }}>
                  <Dropdown.Item className="dropdownList" onClick={() => handleChange('breakType', "rest break", index)}>
                    rest break
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdownList" onClick={() => handleChange('breakType', "meal break", index)}>
                    meal break
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
              for the next{" "}
              <Dropdown className="hourDiv">
                <Dropdown.Toggle variant="light" size="sm" disabled className="dropdownToggle durationSelectedValue">
                  {rule.breakDuration}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto", minWidth: '80px' }}>
                  {generateMinuteOptions('breakDuration',index)}
                </Dropdown.Menu>
              </Dropdown>{" "}
              minutes.
              This rule shall expire after {" "}
                <Dropdown className="hourDiv">
                  <Dropdown.Toggle variant="light" disabled size="sm" className="dropdownToggle durationSelectedValue">
                    {rule.reminderInterval}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "auto", minWidth: '80px' }}>
                    {generateMinuteOptionsForExprire('reminderInterval',index)}
                  </Dropdown.Menu>
                </Dropdown>
                minutes
            </div>

            <div className="mt-3 rulesSubDesc">
              <h6 className="ruleheadTag">When this event occurs, the employees can:</h6>
              <Form.Check
                type="switch"
                id={`accept-break-${index}`}
                label="Accept and Start the break time"
                checked={true}
                disabled
                onChange={(e) => handleChange('acceptBreak', e.target.checked, index)}
                className="mt-2"
              />
              <Form.Check
                type="switch"
                id={`reject-break-${index}`}
                label="Reject/Waive the break"
                checked={true}
                disabled
                onChange={(e) => handleChange('rejectBreak', e.target.checked, index)}
                className="mt-2"
              />
              {/* <Form.Check
                type="checkbox"
                label="Add a penalty of 1 hour pay at regular rate of pay per work day"
                checked={rule.penalty}
                onChange={(e) => handleChange('penalty', e.target.checked, index)}
                className="mt-3 formCheckLabel"
              /> */}

              <Form.Check
                type="switch"
                id={`violation-${index}`}
                label="Include a violation section?"
                checked={rule.violation?.rule_name ? true : false}
                disabled
                onChange={(e) => handleChange('addViolation', e.target.checked, index)}
                className="mt-2"
              />

                {/* Conditionally render input field if rejectBreak is true */}
                {rule.violation?.rule_name && (
                  <div className="mt-3 violationDesc">
                    <div className="col-md-4 col-lg-4 rule_title">
                      <input
                        type="text"
                        className="inputWithBottomBorder"
                        placeholder="Violation name"
                        disabled
                        value={rule.violation.rule_name}
                        autoFocus
                        onChange={(e) => handleChange('violation_rule_name', e.target.value, index, 'violation_rule_name')}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <span className="text-danger bold violationText">Violation</span>
                    </div>
                    <div className="col-md-4 col-lg-4">
                    </div>
                    {violationErrorFields.includes(index) && (
                      <div className="text-danger register-error rule_violation_validation">Violation name is required</div>
                    )}
                    <div className="mt-3 violationDesc1">
                      If the staff has been at work for{" "}
                      <Dropdown className="hourDiv">
                        <Dropdown.Toggle variant="light" size="sm" disabled className="dropdownToggle durationSelectedValue">
                          {rule.violation.workDuration1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto", minWidth: '80px' }}>
                          {generateHourOptions1(index)}
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown className="hourDiv">
                        <Dropdown.Toggle variant="light" size="sm" disabled className="dropdownToggle durationSelectedValue">
                          {rule.violation.workDurationMins1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto", minWidth: '80px' }}>
                          {generateMinuteOptions1(index)}
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown className="hourDiv">
                        <Dropdown.Toggle variant="light" size="sm" disabled className="dropdownToggle durationSelectedValue">
                          {rule.violation.notificationType1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {ruleTypeViolation.map((item) => (
                            <Dropdown.Item 
                              className="dropdownList" 
                              onClick={() => handleChange('notificationType1', { text: item.text_to_be_displayed, id: item.rules_and_alerts_uitext_id }, index, 'notificationType1')}
                              key={item.rules_and_alerts_uitext_id}
                            >
                              {item.text_to_be_displayed}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      {rule.violation.notificationType1 !== "force checkout" ?
                      (
                        <>
                          to review the{" "} 
                          <span className="">violation</span>
                        </>
                      ) :
                      (
                        <>
                      may be required.
                        </>
                      )}
                    </div>
                  </div>
                  )}

            </div>
          </div>
        ))}

        {/* Buttons */}
        <div className="d-flex justify-content-end mt-4">
          <Button className="previousBtn" variant="light" onClick={onPrevious}>
            Previous
          </Button>
          <Button className="previousBtn" variant="primary" onClick={onNexts}>
            Next
          </Button>
        </div>
      </Card.Body>
      
      {/* Bootstrap Modal */}
      {showModal && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          role="dialog"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dimmed background
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content rounded-3 shadow-lg">
              <div className="modal-header bg-primary deleteMgeHeader text-white">
                <h5 className="modal-title">
                  <i className="bi bi-exclamation-triangle-fill me-2"></i>
                  Confirm Delete
                </h5>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  onClick={handleClose}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body text-center">
                <p className="text-secondary modal-body-desc">
                  Are you sure you want to delete this rule? <br />
                  {/* <strong>This action cannot be undone.</strong> */}
                </p>
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary px-4 deleteNoBtn"
                  onClick={handleClose}
                  style={{ borderRadius: '8px' }}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-danger px-4 deleteYesBtn"
                  onClick={handleConfirm}
                  style={{ borderRadius: '8px' }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};


const SaveStep = ({ onPrevious, formData,policyCallBack,premiseId }) => {

  const { showToast } = useToast();

  const handleSubmit = () => {
    // Here, handle form submission logic (e.g., API call)
    console.log("Form Submitted:", formData);
      const authToken = localStorage.getItem('authToken');
      const createPolicy = async () => {
        try {
          const headers = {
            "Authorization": `Bearer ${authToken}`
          };
          const policyCreateResponse = await apiRequest(
            `web_api/admin/insertBreakRules`,
            'POST',
            formData,
            headers
          );
      
          // console.log('policyCreateResponse--->', policyCreateResponse);
      
          if (policyCreateResponse?.data?.status_code === 200) {
            const policy_details = policyCreateResponse.data;
            // console.log('policy_details--->', policy_details);
      
            // Call the callback function
            policyCallBack("created");
      
            // Show the toast message after policyCallBack
            setTimeout(() => {
              toast.success('Policy created successfully!', {
                position: 'top-right',
                autoClose: 4000,
              });
            }, 0); // Add a small delay to ensure policyCallBack completes
          } else {
            // Handle API response with errors or non-200 status codes
            const errorMessage = policyCreateResponse?.data?.message || 'Something went wrong.';
            toast.error(`${errorMessage}`, {
              position: 'top-right',
              autoClose: 4000,
            });
          }
        } catch (error) {
          // Handle network or unexpected errors
          // console.error('Error while creating policy:', error);
          toast.error('Failed to create policy. Please try again later.', {
            position: 'top-right',
            autoClose: 4000,
          });
        }
      };
      
      
      createPolicy();
      
  };

  const [isAgreed, setIsAgreed] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsAgreed(e.target.checked);
  };

  const handleSave = () => {
    if (isAgreed) {
      handleSubmit();
    } else {
      toast.info("Please agree to the terms and conditions to proceed.", {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  return (
  <Card className="shadow-sm p-4" style={{ borderRadius: "10px" }}>
    <Card.Body>
      {/* Title */}
      <h4 className="text-primary mb-4 text-center policyFormTitle">Terms and Conditions</h4>

      {/* Terms and Conditions Section */}
      <div
        className="mt-4 p-4"
        style={{
          backgroundColor: "#ffff",
          border: "1px solid #ddd", // Subtle border
          borderRadius: "8px", // Rounded corners
          boxShadow: "2px 2px 12px 2px rgba(0, 0, 0, 0.15)"
        }}
      >
        {/* Descriptive Text */}
        <p className="text-muted termsAndConditionTitle">
          By accessing this service, you agree to the following terms and conditions. Please ensure you read them thoroughly before proceeding.
        </p>

        {/* Detailed Terms */}
        <ul style={{ listStyleType: "disc", paddingLeft: "20px" }} className="termsDesc">
          <li className="mb-2">
            You agree to provide accurate and truthful information for all required fields.
          </li>
          <li className="mb-2">
            The company reserves the right to modify terms at any time. Updates will be communicated via the provided contact details.
          </li>
          <li className="mb-2">
            Non-compliance with the terms may lead to suspension or termination of your access.
          </li>
          <li className="mb-2">
            Any disputes arising shall be governed by the laws of the respective jurisdiction.
          </li>
          <li className="mb-2">
            Your data will be handled in accordance with our privacy policy.
          </li>
        </ul>

        {/* Checkbox Agreement */}
        <Form.Check
          type="checkbox"
          id = "term-and-condition"
          label="I have read and agree to the terms and conditions"
          className="mt-3 agreeDiv"
          checked={true}
          disabled
          onChange={handleCheckboxChange}
        />
      </div>

      {/* Buttons */}
      {/* <div className="d-flex justify-content-end mt-4">
        <Button className="previousBtn me-2" variant="light" onClick={onPrevious}>
          Previous
        </Button>
        <Button
          className="saveBtn"
          variant="primary"
          onClick={() => {
            if (!isAgreed) {
              alert("Please agree to the terms and conditions to proceed.");
              return;
            }
            handleSave();
          }}
        >
          Save
        </Button>
      </div> */}
      {/* Buttons */}
      <div className="d-flex justify-content-end mt-4">
        <Button className="previousBtn" variant="light" onClick={onPrevious}>
          Previous
        </Button>
        {/* <Button className="previousBtn" variant="primary"         
          onClick={() => {
            if (!isAgreed) {
              toast.info("Please agree to the terms and conditions to proceed.", {
                position: "top-right",
                autoClose: 4000,
              });
              return;
            }
            handleSave();
          }}>
          Save
        </Button> */}
      </div>
    </Card.Body>
  </Card>
  );
};

const PolicyEditForm = ({policyCallBack,premiseId,policyData,policyFormbackButton}) => {
  // console.log('PremiseIdPolicyForm',premiseId)
  const [currentStep, setCurrentStep] = useState(0);
  const [policyName, setPolicyName] = useState("");
  const [policyDate, setPolicyDate] = useState("");
  const [rule_name, setRuleTitle] = useState("");
  const [workDuration, setWorkDuration] = useState("1h");
  const [workDurationMins, setWorkDurationMins] = useState("5m");
  const [breakDuration, setBreakDuration] = useState("5m");
  const [breakType, setBreakType] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [rules_and_alerts_uitext_id, setNotificationTypeId] = useState("");
  const [reminderInterval, setReminderInterval] = useState("10m");
  const [reminderCount, setReminderCount] = useState("3");
  const [restBreak1, setRestBreak1] = useState(true);
  const [acceptBreak, setAcceptBreak] = useState(false);
  const [rejectBreak, setRejectBreak] = useState(false);
  const [penalty, setPenalty] = useState(false);

  const handleNext = () => setCurrentStep((prev) => Math.min(prev + 1, 2));
  const handlePrevious = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

  const customerDetails = JSON.parse(localStorage.getItem('customerDetails'));
  // console.log('premiseId--->',premiseId)
  const formData = {
    user_id : customerDetails.userId,
    policy_id : "",
    premise_id : premiseId,
    policy_name : localStorage.getItem("policyName"),
    effective_start_date : localStorage.getItem("policyDate"),
    clone_policy_id : "",
    is_new_policy : "yes",
    array_insert_breakrules : JSON.parse(localStorage.getItem("rules")),
    // breakDuration,
    // breakType,
    // notificationType,
    // rules_and_alerts_uitext_id,
    // reminderInterval,
    // reminderCount,
    // restBreak1,
    // acceptBreak,
    // rejectBreak,
    // penalty,
  };

  return (
    <Container>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col lg={8}>
          {/* Step Progress and Bar at the top */}
          <StepProgress currentStep={currentStep} />

          {/* Form steps */}
          {currentStep === 0 && (
            <PoliciesStep
              policyFormbackButton={policyFormbackButton}
              policyData = {policyData}
              policyName={policyName}
              setPolicyName={setPolicyName}
              policyDate={policyDate}
              setPolicyDate={setPolicyDate}
              onNext={handleNext}
            />
          )}
          {currentStep === 1 && (
            <RulesStep
              policyData = {policyData}
              onPrevious={handlePrevious}
              onNext={handleNext}
              rule_name = {rule_name}
              setRuleTitle = {setRuleTitle}
              workDuration={workDuration}
              setWorkDuration={setWorkDuration}
              workDurationMins={workDurationMins}
              setWorkDurationMins={setWorkDurationMins}
              breakDuration={breakDuration}
              setBreakDuration={setBreakDuration}
              breakType={breakType}
              setBreakType={setBreakType}
              notificationType={notificationType}
              setNotificationType={setNotificationType}
              reminderInterval={reminderInterval}
              setReminderInterval={setReminderInterval}
              reminderCount={reminderCount}
              setReminderCount={setReminderCount}
              restBreak1={restBreak1}
              setRestBreak1={setRestBreak1}
              acceptBreak={acceptBreak}
              setAcceptBreak={setAcceptBreak}
              rejectBreak={rejectBreak}
              setRejectBreak={setRejectBreak}
              penalty={penalty}
              setPenalty={setPenalty}
            />
          )}
          {currentStep === 2 && <SaveStep policyCallBack={policyCallBack} premiseId={premiseId} onPrevious={handlePrevious} formData={formData} />}
        </Col>
      </Row>
    </Container>
  );
};

export default PolicyEditForm;
