// src/components/DashboardLayout.js
import React, { useEffect } from "react";
import Sidebar from '../Layout/Sidebar';
import Topbar from '../Layout/Topbar';
import './Dashboard.css';
import { ToastContainer, toast } from 'react-toastify';
import { useToast } from "../../contexts/ToastContext";

const Dashboard = () => {
  const { toastMessage, clearToast } = useToast();
  useEffect(() => {
    // console.log('toastMessage-->',toastMessage)
    if (toastMessage) {
      toast.success(toastMessage);
      clearToast(); // Clear the toast message after displaying it
    }
  }, [toastMessage, clearToast]);
  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="main-content dashboardMain">
        <Topbar />
        <ToastContainer />
        <div className="content">
          <div className="contentHeader d-flex align-items-center gap-3 pb-3">
            <div className='contentDiv'>
              <span className='contentTitle'>Dashboard</span>
            </div>
          </div>
          <div className="row">
            <div className="col">

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
