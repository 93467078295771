import React, { useState, useEffect } from 'react';
import { Link, useLocation ,useNavigate} from 'react-router-dom';
import './Sidebar.css';
import logo from '../../assets/logo.svg';

const menuItems = [
  { iconClass: "fas fa-home", label: "Dashboard", path: "/dashboard" },
  { iconClass: "fas fa-solid fa-building", label: "Premises", path: "/premises" },
  { iconClass: "fas fa-users", label: "Users", path: "/users" },
  { iconClass: "fas fa-chart-bar", label: "Reports", path: "/report" },
  { iconClass: "fas fa-gear", label: "Settings", path: "/settings" },
];

const Sidebar = () => {
  const location = useLocation(); // Hook to get the current location
  const [activePath, setActivePath] = useState(location.pathname); // Initialize active path
  const navigate = useNavigate();

  useEffect(() => {
    setActivePath(location.pathname); // Update active path on route change
  }, [location]);

  const handleLogoClick = () => {
    navigate("/dashboard"); // Replace "/dashboard" with the actual route of your dashboard page
  };

  return (
    <div className="sidebar">
      <div className="sidebarDiv">
        <div className="sidebarLogoDiv">
          <img
            src={logo}
            alt="User Avatar"
            className="sideBarLogoImage"
            style={{ cursor: "pointer" }}
            onClick={handleLogoClick}
          />
        </div>
        <div className="sidebarListDiv">
          <ul>
          {menuItems.map((item, index) => {
            const isActive = activePath === item.path || 
            (item.path === '/premises' && activePath.includes('/premises'));
            return (
              <Link to={item.path} key={index} className="sidebar-link">
                <li className={isActive ? 'active' : ''}>
                  <i className={`${item.iconClass} me-2`}></i>
                  {item.label}
                </li>
              </Link>
            );
          })}

          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
