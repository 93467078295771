// encryptionUtils.js
import CryptoJS from 'crypto-js';

export const encryptData = (inputData) => {
  try {
    const keyval = "12345678901234561234567890123456"; // 32-byte key
    const ivVal = "1234567890123456"; // 16-byte IV

    let keyArray = new TextEncoder().encode(keyval);
    let ivArray = new TextEncoder().encode(ivVal);

    if (keyArray.length !== 32) {
      console.error("Key must be 32 bytes for AES-256");
      keyArray = keyArray.slice(0, 32);
    }

    if (ivArray.length !== 16) {
      console.error("IV must be 16 bytes");
      ivArray = ivArray.slice(0, 16);
    }

    const keyWordArray = CryptoJS.lib.WordArray.create(keyArray);
    const ivWordArray = CryptoJS.lib.WordArray.create(ivArray);

    const encrypted = CryptoJS.AES.encrypt(inputData, keyWordArray, {
      iv: ivWordArray,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const encryptedValue = encrypted.toString();
    const encryptedDataString = new TextEncoder().encode(encryptedValue);
    return btoa(String.fromCharCode(...encryptedDataString));
  } catch (error) {
    console.error("Encryption error:", error);
  }
};
