import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Topbar.css";
import adminIcon from "../../assets/user-circles.png";
import { apiRequest } from "../../services/api";
import { toast } from 'react-toastify';
import { encryptData } from "../Common/encryptionUtils";
import Loader from '../Common/Loader';

const Topbar = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [showAlert, setShowAlert] = useState(true); // State for the alert box
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [isPhoneVerified, setIsPhoneVerified] = useState(true);
  const navigate = useNavigate();
console.log('isEmailVerified--->',isEmailVerified)
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    // Clear session storage or local storage
    sessionStorage.clear();
    localStorage.clear();
    navigate("/signin");
  };

  const handleVerify = () => {
    setLoading(true);
    const customerDetails = JSON.parse(localStorage.getItem('customerDetails'));
    console.log('customerDetails-->',customerDetails)
    const encryptedCustomerId = encryptData(customerDetails.customerAccountId.toString());
    const emailVerification = async () => {
      const response = await apiRequest(`web_api/admin/email/send_verify_link?customerAccountId=${encryptedCustomerId}`,'POST',null);
      if(response?.data?.status == 200){
        setLoading(false);
        toast.success('A verification email has been sent to your registered email address. Please check your inbox and click the link to confirm your account activation.', {
          position: 'top-right',
          autoClose: 4000,
        });
      }else{
        setLoading(false);
        toast.error('Email not sent !', {
          position: 'top-right',
          autoClose: 4000,
        });
      }
    }
    emailVerification();
  };

  useEffect(() => {
    // Retrieve the 'username' from sessionStorage when the component mounts
    const customerDetails = JSON.parse(localStorage.getItem('customerDetails'));
    if (customerDetails) {
      setCompanyName(customerDetails.companyName);
    }
    const checkVerifedStatus = async () =>{
        const encryptedCustomerId = encryptData(customerDetails.customerAccountId.toString());
        const authToken = localStorage.getItem('authToken');
        const headers = {
          "Authorization" : `Bearer ${authToken}`
        }
        const response = await apiRequest(`web_api/admin/getCustomerDataById?customer_account_id=${encryptedCustomerId}`,'GET',null,headers)
        if(response?.data?.status_code == 200){
          console.log('response--->',response.data?.data?.email_verified)
          if(response.data?.data?.email_verified === 1){
            setIsEmailVerified(true);
            setIsPhoneVerified(false);
          }
          else{
            setIsEmailVerified(false);
            setIsPhoneVerified(true);
          }
        }else{
          console.log('error in fetch customer details')
        }
    }
    checkVerifedStatus();
  }, []);

  // // Auto-hide alert after 15 seconds
  // useEffect(() => {
  //   const timer = setTimeout(() => setShowAlert(false), 30000);
  //   return () => clearTimeout(timer);
  // }, []);
  console.log('isEmailVerified--->',isEmailVerified)
  return (
    <div className="top-bar navbar navbar-dark d-flex topBarDiv">
      {loading && <Loader />} 
      <div className="col-md-3 companyNameDiv">
        <h1 className="navbar-brand mb-0 text-primary companyName">
          {companyName}
        </h1>
      </div>
      {/* Alert Box */}
      {!isEmailVerified && 
        <div className="alert alert-warning alert-dismissible fade show blink-alert mx-auto col-md-7 alertDiv" role="alert">
          Please verify your email within 15 days!
            <button
              type="button"
              className="btn btn-primary verify-btn"
              onClick={() => handleVerify()}
            >
              Verify
            </button>
            <button
              type="button"
              className="btn-close verifyCloseBtn"
              data-bs-dismiss="alert"
              data-bs-interval="5000"
              aria-label="Close"
              onClick={() => setShowAlert(false)}
            ></button>
        </div>
      }

      {isEmailVerified && isPhoneVerified === false  ? (
        <div className="alert alert-warning alert-dismissible fade show blink-alert mx-auto col-md-7 alertDiv" role="alert">
        Please verify your phone number within 15 days!
          <button
            type="button"
            className="btn btn-primary verify-btn"
            // onClick={() => handleVerify()}
          >
            Verify
          </button>
          <button
            type="button"
            className="btn-close verifyCloseBtn"
            data-bs-dismiss="alert"
            data-bs-interval="5000"
            aria-label="Close"
            onClick={() => setShowAlert(false)}
          ></button>
      </div>
      ):''
      }


      {/* User Section */}
      <div className="user-section d-flex align-items-end position-relative col-md-1 profileIcon" ref={dropdownRef}>
        <img
          src={adminIcon}
          alt="User Avatar"
          className="rounded-circle adminIcon"
          onClick={toggleDropdown}
          style={{ cursor: "pointer" }}
        />
        {dropdownVisible && (
          <div className="dropdown-menu topbar position-absolute">
            <ul>
              <li>Profile</li>
              {/* <li>Settings</li> */}
              <li onClick={handleLogout}>Logout</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Topbar;
