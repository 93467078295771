import React,{useState,useEffect} from "react";
import { Formik, Form, Field,ErrorMessage } from "formik";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import { apiRequest } from "../../services/api";
import "./Registration.css";
import "./Login.css";
import { ToastContainer, toast } from 'react-toastify';
import { useToast } from "../../contexts/ToastContext";
import FirstImage from "../../assets/ic_slider_one.png";
import SecondImage from "../../assets/ic_slider_two.png";
import ThirdImage from "../../assets/ic_slider_three.png";
import FourthImage from "../../assets/ic_slider_four.png";
import Logo from "../../assets/logo.png";
import showPasswordIcon from "../../assets/ph_eye.png";
import hidePasswordIcon from "../../assets/eye.png";
import { encryptData } from '../Common/encryptionUtils';
import Loader from "../Common/Loader";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); 
  const [showPassword, setShowPassword] = useState(false);
  const { showToast } = useToast();
  const { toastMessage, clearToast } = useToast();
  const initialValues = {
    email: "",
    password: "",
    termsAndConditions: false,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required"),
    termsAndConditions: Yup.boolean()
      .oneOf([true], "You must accept the Terms and Conditions and Privacy Policy")
      .required("You must accept the Terms and Conditions and Privacy Policy"),
  });

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    // const token = localStorage.getItem('authToken');
    if (token) {
      navigate('/dashboard'); // Redirect to dashboard if already logged in
    }
  }, []); // Only run on mount

  const handleSubmit = async (values) => {
    setLoading(true);
    try{
      const payload = {
        email : encryptData(values.email),
        password : encryptData(values.password)
    };
      // console.log('payload-->',payload)
      const response = await apiRequest('web_api/admin/admin_login','POST',payload)
      // console.log('response--->',response)
      if (response.data?.status == 200) {
        const customerAccountId = response.data?.data?.customer_account_id;
        const companyName = response.data?.data?.company_name;
        const userId = response.data?.data?.user_id;
        const authToken = response.data?.data?.token;
        localStorage.setItem('customerDetails', JSON.stringify({ customerAccountId,companyName,userId }));
        localStorage.setItem('authToken', authToken);
        setTimeout (()=>{
          setLoading(false);
        },1000)
        showToast(response.data?.message);
        navigate('/dashboard'); // Navigate to Dashbaord
      }else if(response.data?.status == 400){
        toast.error(response.data?.errors || 'An unexpected error occurred.');
      }
      else {
        // console.log('sdsdsds')
        toast.error(response.data?.data?.all || 'An unexpected error occurred.');
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Login failed. Please try again.'); // Handle specific error messages from API
    } finally {
      setTimeout (()=>{
        setLoading(false);
      },1000)
    }
  };

  useEffect(() => {
    if (toastMessage) {
      // console.log('toastMessage',toastMessage)
      toast.success(toastMessage);
      clearToast(); // Clear the toast message after displaying it
    }
  }, [toastMessage, clearToast]);

  return (
    <div className="container-fluid p-0">
      {/* Header */}
      <ToastContainer />
      {loading && <Loader />} 
      <header className="header-section">
        <div className="container row">
          <div className="LogoDiv col-md-8">
          <img src={Logo} className="logoImage" alt="Logo" />
          </div>
          <div className="headerDesc col-md-4">
          <span className="contactUsDesc">Talk with our team at <span className="text-primary contactNo">+91.6368691500</span></span>
          </div>
          {/* <h1 className="text-center text-white">Welcome to Business Time!</h1> */}
        </div>
      </header>

      {/* Main content area */}
      <div className="main-content d-flex vh-100">
        {/* Left Side - Slider */}
        <div className="left-slider">
          <h4 className="mb-3 text-center text-primary registerFormTitle">Welcome to Business Time!</h4>
          <p className="text-center text-muted registerFormDesc">
            Streamline Your Workforce To Achieve Greater Efficiency.
          </p>
          <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="4000">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="sliderImageDiv">
                  <img src={FirstImage} className="d-block w-100 h-100 slideImage" alt="Slide 1" />
                </div>
                <p className="sliderImgDesc text-muted">Simple and Efficient Management and </p>
                <p className="sliderImgDesc1 text-muted">Monitoring of Employee Rest and Meal Breaks.</p>
              </div>
              <div className="carousel-item">
                <div className="sliderImageDiv">
                  <img src={SecondImage} className="d-block w-100 h-100 slideImage" alt="Slide 2" />
                </div>
                <p className="sliderImgDesc text-muted">Seamless Time-Tracking and</p>
                <p className="sliderImgDesc1 text-muted">Automated Attendance Management.</p>
              </div>
              {/* <div className="carousel-item">
                <div className="sliderImageDiv">
                  <img src={ThirdImage} className="d-block w-100 h-100 slideImage" alt="Slide 3" />
                </div>
                <p className="sliderImgDesc text-muted">track business open & close time.</p>
              </div> */}
              {/* <div className="carousel-item">
                <div className="sliderImageDiv">
                  <img src={FourthImage} className="d-block w-100 h-100 slideImage" alt="Slide 4" />
                </div>
                <p className="sliderImgDesc text-muted">know the demography of your customers.</p>
              </div> */}
            </div>
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              {/* <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button> */}
              {/* <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button> */}
            </div>
          </div>
        </div>

        {/* Right Side - Form */}
        <div className="login-right-form">
          <div className="form-container">
            <h3 className="text-center mb-4 form-title">Sign in</h3>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <div className="loginDiv">
                    <Form className="registerForm" autoComplete="off">
                    <div className="row mb-2 loginInputDiv">
                        <div className="col-md-12">
                        <div className="form-group">
                            <Field
                            type="email"
                            name="email"
                            id="email"
                            className="login-form-input"
                            placeholder=" "
                            autoFocus
                            />
                            <label htmlFor="email" className="form-label">
                            Email <span className="text-danger">*</span>
                            </label>
                        </div>
                        <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger register-error"
                        />
                        </div>
                    </div>

                        <div className="mb-2 row loginInputDiv">
                        <div className="col-md-12">
                            <div className="form-group">
                              <div className="passwordDiv">
                                <Field
                                  type={showPassword ? 'text' : 'password'}
                                  name="password"
                                  id="password"
                                  className="login-form-input"
                                  placeholder=" "
                              />
                              <label htmlFor="password" className="form-label">
                                  Password <span className='text-danger'>*</span>
                              </label>
                              <span className="login-toggle-password" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? (
                                  <img className='show-password-icon' src={showPasswordIcon} alt="ShowPasswordIcon" />
                                ) : (
                                  <img className='show-password-icon' src={hidePasswordIcon} alt="ShowPasswordIcon" />
                                )}
                              </span>
                              </div>
                            </div>
                            <ErrorMessage
                                name="password"
                                component="div"
                                className="text-danger register-error"
                            />
                            </div>
                        </div>
                        <div className="row mb-0">
                      <div className="col-md-12 mb-3">
                        <div className="form-group" style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                          {/* First Line - Checkbox and Terms & Conditions */}
                          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <Field
                              type="checkbox"
                              name="termsAndConditions"
                              id="termsAndConditions"
                              className="termsAndConditions"
                            />
                            <label htmlFor="termsAndConditions" className="form-label termAndCondition" style={{ marginLeft: '8px', flex: '1' }}>
                              By signing up, you agree to our{" "}
                              <Link to="https://businesstime.app/terms-and-conditions.html" target="_blank" className="SignInLink">
                                Terms and 
                              </Link>
                            </label>
                          </div>

                          {/* Second Line - Privacy Policy Link */}
                          <label htmlFor="termsAndConditions" className="form-label termAndConditionSecond" style={{ width: '100%', textAlign: 'left' }}>
                              <Link to="https://businesstime.app/terms-and-conditions.html" target="_blank" className="SignInLink">
                                Conditions
                              </Link>
                              <span className="p-1">and</span>
                            <Link to="https://businesstime.app/privacy-policy.html" target="_blank" className="SignInLink">
                              Privacy Policy
                            </Link>
                          </label>
                        </div>
                        <ErrorMessage
                          name="termsAndConditions"
                          component="div"
                          className="text-danger register-error termsAndConditionsLoginError"
                        />
                      </div>
                    </div>
                        <div className="row loginInputDiv">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <button type="submit" className="btn loginBtn">
                                        Sign In
                                    </button>
                                </div>
                            </div>
                        </div>

                    <div className="signupLink">
                        <span>
                        Don't have an account?
                        <Link to="/registration" className="SignUpLink"> Sign Up</Link>
                        </span>
                    </div>

                    </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
