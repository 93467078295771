import React,{useEffect, useState} from "react";
import CryptoJS from 'crypto-js';
import { Formik, Form, Field,ErrorMessage } from "formik";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from 'react-toastify';
import "./Registration.css";
import sliderOne from "../../assets/ic_slider_one.png";
import sliderTwo from "../../assets/ic_slider_two.png";
import sliderThree from "../../assets/ic_slider_three.png";
import sliderFour from "../../assets/ic_slider_four.png";
import Logo from "../../assets/logo.png";
import showPasswordIcon from "../../assets/ph_eye.png";
import hidePasswordIcon from "../../assets/eye.png";
import { apiRequest } from "../../services/api";
import { useToast } from "../../contexts/ToastContext";
import { encryptData } from '../Common/encryptionUtils';
import Loader from "../Common/Loader";

const Registration = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); 
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { showToast } = useToast();
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phone_no_country_code : "",
    companyName: "",
    company_phone_country_code : "",
    noOfEmployees: "",
    companyPhoneNo: "",
    address: "",
    password: "",
    confirmPassword: "",
    termsAndConditions: false,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("First Name is required")
      .max(50, "First Name can't exceed 50 characters"),
    lastName: Yup.string()
      .required("Last Name is required")
      .max(50, "Last Name can't exceed 50 characters"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    // countryCode: Yup.string()
    //   .required("Country Code is required")
    //   .matches(/^\+\d+$/, "Must start with '+' and contain digits"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
    companyName: Yup.string()
      .required("Company Name is required")
      .max(100, "Company Name can't exceed 100 characters"),
    noOfEmployees: Yup.number()
      .typeError("Must be a number")
      .required("Number of employees is required")
      .min(1, "Must have at least 1 employee"),
    // companyPhoneNo: Yup.string()
    //   .required("Company Phone No is required")
    //   .matches(/^\d{10}$/, "Must be a valid 10-digit phone number"),
    address: Yup.string()
      .required("Address is required")
      .max(200, "Address can't exceed 200 characters"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(/[A-Za-z]/, "Password must contain at least one letter")
      .matches(/[0-9]/, "Password must contain at least one number"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
    termsAndConditions: Yup.boolean()
      .oneOf([true], "You must accept the Terms and Conditions and Privacy Policy")
      .required("You must accept the Terms and Conditions and Privacy Policy"),
  });


  const [countryCode, setCountryCode] = useState("+91"); // Default country code
  const [countryId, setCountryId] = useState("+91"); // Default country code
  const [companyCountryCode, setCompanyCountryCode] = useState("+91"); // Default country code
  const [companycountryId, setCompanyCountryId] = useState("+91"); // Default country code
  const [countryList, setCountryList] = useState([]); // Default country code
  const [phone, setPhone] = useState(""); // Phone number state
  const [dropdownVisible, setDropdownVisible] = useState(false); // Dropdown visibility state
  const [dropdownCompanyCodeVisible, setDropdownCompanyCodeVisible] = useState(false); // Dropdown visibility state


  // console.log('countryCode-->',countryId)
  // console.log('countryCode-->',companycountryId)

  // Function to handle country code change
  const handleCountryCodeChange = (e) => {
    // console.log('Value-->',e.target)
    setCountryCode(e.target.value);
    // setCountryId(e.target.country_code)
    setDropdownVisible(false); // Hide dropdown after selecting a country code
  };

  // Function to handle country code change
  const handleCompanyCountryCodeChange = (e) => {
    // console.log('Value-->',e.target)
    setCompanyCountryCode(e.target.value);
    // setCompanyCountryId(e.target.country_code)
    setDropdownCompanyCodeVisible(false); // Hide dropdown after selecting a country code
  };

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleDropdownCompanyCode = () => {
    setDropdownCompanyCodeVisible(!dropdownCompanyCodeVisible);
  };

  // Function to handle phone input change
  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
    setPhone(value); // Update phone number
  };


  const handleSubmit = async (values) => {
    // console.log("Form submitted:", values);
    setLoading(true);
    try{
      console.log('payloadvalues-->',values)
      const payload = {
        first_name: encryptData(values.firstName),
        last_name: encryptData(values.lastName),
        email: encryptData(values.email),
        phone: encryptData(values.phone),
        phone_no_country_code : countryCode,
        company_name: encryptData(values.companyName),
        company_phone_country_code : companyCountryCode,
        company_phone: encryptData(values.companyPhoneNo),
        number_of_employees: encryptData(values.noOfEmployees),
        address: encryptData(values.address),
        password : encryptData(values.password)
      };
      console.log('payload-->',payload)
      const response = await apiRequest('web_api/admin/create_account','POST',payload)
      // console.log('response--->',response)
      if (response.data?.status_code == 200) {
        setTimeout (()=>{
          setLoading(false);
        },200)
        showToast(response.data?.message);
        navigate('/signin'); // Navigate to SignIn
      } else {
        console.log('sdsdsds',response.data)
        toast.error(response.data?.message || 'An unexpected error occurred.');
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Login failed. Please try again.'); // Handle specific error messages from API
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCountryCode = async () => {
      const countryCodeResponse = await apiRequest('api/user/getcountry','GET');
      // console.log('countryCodeResponse--->',countryCodeResponse)
      if(countryCodeResponse.data?.status_code == 200){
        // console.log('CountryCodeDetails--->',countryCodeResponse.data?.data?.country_details)
        setCountryList(countryCodeResponse.data?.data?.country_details)
      }

    }
    fetchCountryCode();
  },[]);

  return (
    <div className="container-fluid p-0">
      {/* Header */}
      <ToastContainer />
      {loading && <Loader />} 
      <header className="header-section">
        <div className="container row">
          <div className="LogoDiv col-md-8">
          <img src={Logo} className="logoImage" alt="Logo" />
          </div>
          <div className="headerDesc col-md-4">
          <span className="contactUsDesc">Talk with our team at <span className="text-primary contactNo">+91.6368691500</span></span>
          </div>
          {/* <h1 className="text-center text-white">Welcome to Business Time!</h1> */}
        </div>
      </header>

      {/* Main content area */}
      <div className="main-content registrationMainDiv d-flex vh-100">
        {/* Left Side - Slider */}
        <div className="left-slider">
          <h4 className="mb-3 text-center text-primary registerFormTitle">Welcome to Business Time!</h4>
          <p className="text-center text-muted registerFormDesc">
            Streamline Your Workforce To Achieve Greater Efficiency.
          </p>
          <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="4000">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="sliderImageDiv">
                  <img src={sliderOne} className="d-block w-100 h-100 slideImage" alt="Slide 1" />
                </div>
                <p className="sliderImgDesc text-muted">Simple and Efficient Management and </p>
                <p className="sliderImgDesc1 text-muted">Monitoring of Employee Rest and Meal Breaks.</p>
              </div>
              <div className="carousel-item">
                <div className="sliderImageDiv">
                  <img src={sliderTwo} className="d-block w-100 h-100 slideImage" alt="Slide 2" />
                </div>
                <p className="sliderImgDesc text-muted">Seamless Time-Tracking and</p>
                <p className="sliderImgDesc1 text-muted">Automated Attendance Management.</p>
              </div>
              {/* <div className="carousel-item">
                <div className="sliderImageDiv">
                  <img src={sliderThree} className="d-block w-100 h-100 slideImage" alt="Slide 3" />
                </div>
                <p className="sliderImgDesc text-muted">Track business open & close time.</p>
              </div> */}
              {/* <div className="carousel-item">
                <div className="sliderImageDiv">
                  <img src={sliderFour} className="d-block w-100 h-100 slideImage" alt="Slide 4" />
                </div>
                <p className="sliderImgDesc text-muted">Know the demography of your customers.</p>
              </div> */}
            </div>
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              {/* <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button> */}
            </div>
          </div>
        </div>

        {/* Right Side - Form */}
        <div className="right-form">
          <div className="form-container"> 
            <h3 className="text-center mb-4 form-title">Create Your Account</h3>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, touched, errors }) => (
                <Form className="registerForm" autoComplete="off">
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Field
                          type="text"
                          name="firstName"
                          id="firstName"
                          className="form-input"
                          placeholder=" "
                          autoFocus
                          autoComplete="off"
                        />
                        <label htmlFor="firstName" className="form-label">
                          First Name <span className="text-danger">*</span>
                        </label>
                      </div>
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-danger register-error"
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Field
                          type="text"
                          name="lastName"
                          id="lastName"
                          className="form-input"
                          placeholder=" "
                          autoComplete="off"
                        />
                        <label htmlFor="lastName" className="form-label">
                          Last Name <span className="text-danger">*</span>
                        </label>
                      </div>
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-danger register-error"
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-12">
                      <div className="form-group">
                        <Field
                          type="email"
                          name="email"
                          id="email"
                          className="form-input"
                          placeholder=" "
                        />
                        <label htmlFor="email" className="form-label">
                          Email <span className="text-danger">*</span>
                        </label>
                      </div>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger register-error"
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-12">
                      <div className="form-group">
                        <Field
                          type="text"
                          name="companyName"
                          id="companyName"
                          className="form-input"
                          placeholder=" "
                        />
                        <label htmlFor="companyName" className="form-label">
                          Company Name <span className="text-danger">*</span>
                        </label>
                      </div>
                      <ErrorMessage
                          name="companyName"
                          component="div"
                          className="text-danger register-error"
                        />
                      </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Field
                          type="text"
                          name="noOfEmployees"
                          id="noOfEmployees"
                          className="form-input"
                          placeholder=" "
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                          }}
                        />
                        <label htmlFor="noOfEmployees" className="form-label">
                          No. of Employees <span className="text-danger">*</span>
                        </label>
                      </div>
                      <ErrorMessage
                        name="noOfEmployees"
                        component="div"
                        className="text-danger register-error"
                      />
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                      <div
                        className="form-input country-code-box"
                        onClick={toggleDropdown}
                        style={{
                          cursor: "pointer",
                          padding: "9.2px 3px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          width: "55px",
                        }}
                      >
                        {countryCode} {/* Display selected country code */}
                        <i
                          className="fas fa-chevron-down"
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "50%",
                            transform: "translateY(-50%)", // To vertically center the icon
                            fontSize: "12px", // Adjust size if necessary
                            color: "#666", // Adjust color if needed
                          }}
                        ></i>
                      </div>
                            {/* Country Code Dropdown */}
                    {dropdownVisible && (
                      <Dropdown show={dropdownVisible} onToggle={setDropdownVisible}>
                        <Dropdown.Menu className="countryCodeDivOption">
                          {countryList.map((code) => (
                            <Dropdown.Item
                              key={code.country_id}
                              onClick={() =>
                                handleCountryCodeChange({
                                  target: { value: code.country_code },
                                })
                              }
                            >
                              {code.country_code} ({code.country_name})
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                      </div>
                    </div>
                    <div className="col-md-4 countryCodeDiv">
                      <div className="form-group">
                        <Field
                          type="text"
                          name="phone"
                          id="phone"
                          className="form-input"
                          placeholder=" "
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                          }}
                        />
                        <label htmlFor="phone" className="form-label">
                          Phone <span className="text-danger">*</span>
                        </label>
                      </div>
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="text-danger register-error"
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                  <div className="col-md-2">
                      <div className="form-group companyCode">
                      <div
                        className="form-input country-code-box"
                        onClick={toggleDropdownCompanyCode}
                        style={{
                          cursor: "pointer",
                          padding: "9.2px 6px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          width: "55px",
                        }}
                      >
                        {companyCountryCode} {/* Display selected country code */}
                        <i
                          className="fas fa-chevron-down"
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "50%",
                            transform: "translateY(-50%)", // To vertically center the icon
                            fontSize: "12px", // Adjust size if necessary
                            color: "#666", // Adjust color if needed
                          }}
                        ></i>
                      </div>
                            {/* Country Code Dropdown */}
                    {dropdownCompanyCodeVisible && (
                      <Dropdown
                      show={dropdownCompanyCodeVisible}
                      onToggle={(isOpen) => setDropdownCompanyCodeVisible(isOpen)}
                      className="mb-3"
                    >
                
                      <Dropdown.Menu  className="countryCodeDivOption">
                        {countryList.map((code) => (
                          <Dropdown.Item
                            key={code.country_id}
                            onClick={() =>
                              handleCompanyCountryCodeChange({
                                target: { value: code.country_code },
                              })
                            }
                            className="dropdown-item"
                            
                          >
                            {code.country_code} ({code.country_name})
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                      )}
                      </div>
                    </div>
                      <div className="col-md-10 companyPhoneNoDiv">
                        <div className="form-group">
                          <Field
                            type="text"
                            name="companyPhoneNo"
                            id="companyPhoneNo"
                            className="form-input companyPhoneNo"
                            placeholder=""
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                            }}
                          />
                          <label htmlFor="phone" className="form-label">
                          Company Phone No
                          </label>
                        </div>
                        {/* <ErrorMessage
                          name="companyPhoneNo"
                          component="div"
                          className="text-danger register-error"
                        /> */}
                      </div>
                  </div>

                    <div className="row mb-2">
                      <div className="col-md-12">
                        <div className="form-group">
                          <Field
                            as="textarea"
                            col="10"
                            row="10"
                            type="text"
                            name="address"
                            id="address"
                            className="form-input"
                            placeholder=" "
                          />
                          <label htmlFor="address" className="form-label addressFormLabel">
                            Address <span className='text-danger'>*</span>
                          </label>
                        </div>
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="text-danger register-error"
                        />
                       </div>
                    </div>

                    <div className="mb-2 row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="passwordDiv">
                          <Field
                            type={showNewPassword ? 'text' : 'password'}
                            name="password"
                            id="password"
                            className="form-input"
                            placeholder=" "
                          />
                          <label htmlFor="password" className="form-label">
                            Set Password <span className='text-danger'>*</span>
                          </label>
                          <span className="change-toggle-password" onClick={() => setShowNewPassword(!showNewPassword)}>
                            {showNewPassword ? <img className='show-password-icon' src={showPasswordIcon} alt="ShowPasswordIcon" /> : <img className='show-password-icon' src={hidePasswordIcon} alt="ShowPasswordIcon" />}
                          </span>
                          </div>
                          </div>
                          {/* <ErrorMessage
                            name="password"
                            component="div"
                            className="text-danger register-error"
                          /> */}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                          <div className="passwordDiv">
                            <Field
                              type={showConfirmPassword ? 'text' : 'password'}
                              name="confirmPassword"
                              id="confirmPassword"
                              className="form-input"
                              placeholder=" "
                            />
                            <label htmlFor="confirmPassword" className="form-label registerLabel">
                              Confirm Password <span className='text-danger'>*</span>
                            </label>
                            <span className="change-toggle-password" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                              {showConfirmPassword ? <img className='show-password-icon' src={showPasswordIcon} alt="ShowPasswordIcon" /> : <img className='show-password-icon' src={hidePasswordIcon} alt="ShowPasswordIcon" />}
                            </span>
                          </div>
                          </div>
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="text-danger register-error"
                          />
                        </div>  
                        {/* Dynamic Validation Checkmarks */}
                        <div className="passwordValidationDiv">
                          <div>
                          {values.password.length === 0 ? (
                            <span className="passwordBullet">•</span>
                          ) : values.password.length >= 8 ? (
                            <span className="passwordTick" style={{ color: "green" }}>✔</span>
                          ) : (
                            <span className="passwordTick" style={{ color: "red" }}>✘</span>
                          )}{" "}
                            <span className="passwordValidation">Use 8 or more characters</span>
                          </div>
                          <div>
                          {values.password.length === 0 ? (
                            <span className="passwordBullet">•</span>
                          ) : /[A-Za-z]/.test(values.password) ? (
                            <span style={{ color: "green" }}>✔</span>
                          ) : (
                            <span style={{ color: "red" }}>✘</span>
                          )}{" "}
                            <span className="passwordValidation">Use a minimum of one letter</span>
                          </div>
                          <div>
                          {values.password.length === 0 ? (
                            <span className="passwordBullet">•</span>
                          ) : /[0-9]/.test(values.password) ? (
                            <span style={{ color: "green" }}>✔</span>
                          ) : (
                            <span style={{ color: "red" }}>✘</span>
                          )}{" "}
                            <span className="passwordValidation">Use a minimum of one number</span>
                          
                          </div>
                        </div>       
                    </div>

                    
                    <div className="row mb-0">
                      <div className="col-md-12 mb-3">
                        <div className="form-group" style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                          {/* First Line - Checkbox and Terms & Conditions */}
                          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <Field
                              type="checkbox"
                              name="termsAndConditions"
                              id="termsAndConditions"
                              className="termsAndConditions"
                            />
                            <label htmlFor="termsAndConditions" className="form-label termAndCondition" style={{ marginLeft: '8px', flex: '1' }}>
                              By signing up, you agree to our{" "}
                              <Link to="https://businesstime.app/terms-and-conditions.html" target="_blank" className="SignInLink">
                                Terms and Conditions
                              </Link>
                              <span className="p-1">and</span>
                            </label>
                          </div>

                          {/* Second Line - Privacy Policy Link */}
                          <label htmlFor="termsAndConditions" className="form-label termAndConditionSecond" style={{ width: '100%', textAlign: 'left' }}>
                            <Link to="https://businesstime.app/privacy-policy.html" target="_blank" className="SignInLink">
                              Privacy Policy
                            </Link>
                          </label>
                        </div>
                        <ErrorMessage
                          name="termsAndConditions"
                          component="div"
                          className="text-danger register-error termsAndConditionsError"
                        />
                      </div>
                    </div>


                  <button type="submit" className="btn w-100 registerBtn">
                    Create account
                  </button>
                  <div className="LoginAccount">
                    <span>
                      Already have an account?
                      <Link to="/signin" className="SignInLink"> Sign In</Link>
                    </span>
                  </div>

                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
