// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, children }) => {
  // Check if a valid token exists in session storage
//   const token = sessionStorage.getItem('authToken'); 
  const token = localStorage.getItem('authToken'); 


  return token ? children : <Navigate to="/signin" replace />;
};

export default PrivateRoute;
