// /src/contexts/ToastContext.js

import React, { createContext, useContext, useState } from 'react';

// Create the ToastContext
const ToastContext = createContext();

// Create the provider component
export const ToastProvider = ({ children }) => {
  const [toastMessage, setToastMessage] = useState(null);

  const showToast = (message) => setToastMessage(message);

  const clearToast = () => setToastMessage(null);

  return (
    <ToastContext.Provider value={{ toastMessage, showToast, clearToast }}>
      {children}
    </ToastContext.Provider>
  );
};

// Custom hook for using the toast context
export const useToast = () => useContext(ToastContext);
