import React, { useState } from "react";
import Sidebar from "../Layout/Sidebar";
import Topbar from "../Layout/Topbar";
import "./Report.css";
import { ToastContainer, toast } from "react-toastify";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Main style file
import 'react-date-range/dist/theme/default.css'; // Theme CSS

const Report = () => {
  
  const [filterOpen, setFilterOpen] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(''); // Keep track of the selected status

  const [showPaymentOptions, setShowPaymentOptions] = useState(false); // For Payment Status toggle
  const [showBreakOptions, setShowBreakOptions] = useState(false); // For Break Type toggle
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(''); // Payment status state
  const [selectedBreakType, setSelectedBreakType] = useState(''); // Break type state

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  
  // Mock API Data for Testing
  const mockData = [
    { id: 1, name: "John Doe", date: "2024-12-10", type: "Paid Break", location: "New York", status: "Active", violation: "None", department: "HR", manager: "Alice" },
    { id: 2, name: "Jane Smith", date: "2024-12-11", type: "Unpaid Break", location: "Los Angeles", status: "Inactive", violation: "Minor", department: "Finance", manager: "Bob" },
    { id: 3, name: "Alice Brown", date: "2024-12-12", type: "Paid Break", location: "Chicago", status: "Active", violation: "Major", department: "IT", manager: "Charlie" },
    { id: 4, name: "Mark Wilson", date: "2024-12-12", type: "Resolved Violation", location: "San Francisco", status: "Resolved", violation: "None", department: "Sales", manager: "David" },
    { id: 5, name: "Chris Adams", date: "2024-12-13", type: "Pending Violation", location: "Seattle", status: "Pending", violation: "Severe", department: "Marketing", manager: "Eve" },
    { id: 6, name: "Eva Thomas", date: "2024-12-14", type: "Paid Break", location: "Dallas", status: "Active", violation: "None", department: "HR", manager: "Alice" },
    { id: 7, name: "Michael Lee", date: "2024-12-15", type: "Unpaid Break", location: "Austin", status: "Inactive", violation: "Minor", department: "Finance", manager: "Bob" },
    { id: 8, name: "Sophia Green", date: "2024-12-16", type: "Paid Break", location: "Miami", status: "Active", violation: "Major", department: "IT", manager: "Charlie" },
    { id: 9, name: "James White", date: "2024-12-17", type: "Resolved Violation", location: "San Diego", status: "Resolved", violation: "None", department: "Sales", manager: "David" },
    { id: 10, name: "Lucas Black", date: "2024-12-18", type: "Pending Violation", location: "Portland", status: "Pending", violation: "Severe", department: "Marketing", manager: "Eve" },
  ];

  // Select/Deselect All Rows
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRows(mockData.map((item) => item.id));
    } else {
      setSelectedRows([]);
    }
  };

  // Select/Deselect a Single Row
  const handleRowSelect = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleCloseDatePicker = () => {
    setShowDatePicker(false);
  };
  const handleBreakTypeChange = (event) => {
    setSelectedBreakType(event.target.value);
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0'); // Adds leading zero if day < 10
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adds leading zero if month < 10
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  

  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="main-content dashboardMain">
        <Topbar />
        <ToastContainer />
        <div className="content d-flex">
          {/* Filter Sidebar */}
          <div className={`pt-3 filter-sidebar ${filterOpen ? "open" : "collapsed"} shadow`} >
            <div className="filterIconDiv ">
              {filterOpen && (
                <h1 className="text-center text-primary filter-title display-4">Filters</h1>
              )}
              <button
                className="toggle-button mt-2 btn btn-primary d-flex align-items-center justify-content-center"
                onClick={() => setFilterOpen(!filterOpen)}
              >
                {filterOpen ? (
                  <i className="fa-solid fa-arrow-left"></i>
                ) : (
                  <i className="fa-solid fa-arrow-right"></i>
                )}
              </button>
            </div>
            {filterOpen && (
              <div className="filter-content mt-2">
                <div className="mb-3 ">
                  <button
                    className="btn selectDateRange w-100 shadow-sm"
                    onClick={() => setShowDatePicker(!showDatePicker)} // Toggle popup visibility
                  >
                    Date Range   <span>
                      <i class="fa-solid fa-calendar-days"></i>
                    </span>
                  </button>
                    {/* Date Range Applied Text */}
                    {dateRange[0].startDate && dateRange[0].endDate && (
                      <div className="mt-1 text-muted">
                        <strong className="filterLabel">Date Range Applied:</strong>
                        <span className="d-block mt-0 dateRangespan filterLabel" style={{ color: '#007bff' }}>
                          <span>{formatDate(dateRange[0].startDate)}</span> - <span>{formatDate(dateRange[0].endDate)}</span>
                        </span>
                      </div>
                    )}
                  {showDatePicker && (
                    <div
                      className="modal fade show"
                      id="dateRangeModal"
                      tabIndex="-1"
                      aria-labelledby="dateRangeModalLabel"
                      aria-hidden="false"
                      role="dialog"

                      style={{ display: 'block',backgroundColor: 'rgba(0, 0, 0, 0.5)', }}  // Ensures the modal is visible when showDatePicker is true
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content dateRangeModal">
                          {/* Modal Header */}
                          <div className="modal-header">
                            <h5 className="modal-title" id="dateRangeModalLabel">Choose Date Range</h5>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={handleCloseDatePicker}
                              aria-label="Close"
                            ></button>
                          </div>

                          {/* Modal Body */}
                          <div className="modal-body">
                            <DateRangePicker
                              onChange={(item) => setDateRange([item.selection])}
                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={2}
                              ranges={dateRange}
                              direction="horizontal"
                              maxDate={new Date()}

                            />
                          </div>

                          {/* Modal Footer */}
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-success deleteYesBtn"
                              onClick={() => {
                                // toast.success(
                                //   `Date Range Applied: ${dateRange[0].startDate.toLocaleDateString()} - ${dateRange[0].endDate.toLocaleDateString()}`
                                // );
                                setShowDatePicker(false);
                              }}
                            >
                              Apply
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger deleteNoBtn"
                              onClick={handleCloseDatePicker}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="mb-0 border-top border-bottom py-2">
                  <div>
                    <label
                      className="d-flex justify-content-between filterLabel"
                      onClick={() => setShowPaymentOptions(!showPaymentOptions)} // Toggle payment options visibility
                    >
                      Payment Status
                      <span>
                      <i className={`fa-solid ${showPaymentOptions ? 'fa-angle-up' : 'fa-angle-down'}`} /> {/* Font Awesome icon */}
                      </span>
                    </label>
                  </div>

                  {showPaymentOptions && (
                    <div className="mt-2 radioDiv">
                      <div className="form-check filter-form-check">
                        <input
                          className="form-check-input"
                          id="Paid"
                          type="radio"
                          name="paymentStatus"
                          value="Paid"
                          checked={selectedStatus === 'Paid'}
                          onChange={handleStatusChange}
                        />
                        <label className="form-check-label radioLabel" for="Paid">Paid</label>
                      </div>
                      <div className="form-check filter-form-check">
                        <input
                          className="form-check-input"
                          id="Unpaid"
                          type="radio"
                          name="paymentStatus"
                          value="Unpaid"
                          checked={selectedStatus === 'Unpaid'}
                          onChange={handleStatusChange}
                        />
                        <label className="form-check-label radioLabel" for="Unpaid">Unpaid</label>
                      </div>
                      <div className="form-check filter-form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="bothValue"
                          name="paymentStatus"
                          value="Both"
                          checked={selectedStatus === 'Both'}
                          onChange={handleStatusChange}
                        />
                        <label className="form-check-label radioLabel" for="bothValue">Both</label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="border-top border-bottom py-2">
                  <label
                    className="d-flex justify-content-between filterLabel"
                    onClick={() => setShowBreakOptions(!showBreakOptions)} // Toggle break options visibility
                  >
                    Break Type
                    <span>
                      <i className={`fa-solid ${showBreakOptions ? 'fa-angle-up' : 'fa-angle-down'}`} /> {/* Font Awesome icon */}
                    </span>
                  </label>

                  {showBreakOptions && (
                    <div className="mt-2 radioDiv">
                      <div className="form-check filter-form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="Meal"
                          name="breakType"
                          value="Meal"
                          checked={selectedBreakType === 'Meal'}
                          onChange={handleBreakTypeChange}
                        />
                        <label className="form-check-label radioLabel" for="Meal">Meal</label>
                      </div>
                      <div className="form-check filter-form-check">
                        <input
                          className="form-check-input radioLabel"
                          type="radio"
                          id="Rest"
                          name="breakType"
                          value="Rest"
                          checked={selectedBreakType === 'Rest'}
                          onChange={handleBreakTypeChange}
                        />
                        <label className="form-check-label radioLabel" for="Rest">Rest</label>
                      </div>
                      <div className="form-check filter-form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="both"
                          name="breakType"
                          value="Both"
                          checked={selectedBreakType === 'Both'}
                          onChange={handleBreakTypeChange}
                        />
                        <label className="form-check-label radioLabel" for="both">Both</label>
                      </div>
                    </div>
                  )}
                </div>

              </div>
            )}
          </div>

          {/* Main Content */}
          <div className="content-area flex-grow-1 pt-3">
            {/* Top Sections */}
            <div className="row mb-4">
              <div className="col-md-8">
                <div className="top-section text-center rounded bg-light">
                  <h5 className="breakMainTitle">Break (30)</h5>
                  <div className="d-flex justify-content-around mt-2">
                    <div>
                      <h6 className="countDetails">15</h6>
                      <p className="text-muted mb-1 breakTitle">Paid</p>
                    </div>
                    <div>
                      <h6 className="countDetails">14</h6>
                      <p className="text-muted mb-1 breakTitle">Unpaid</p>
                    </div>
                    <div>
                      <h6 className="countDetails">15</h6>
                      <p className="text-muted mb-1 breakTitle">Rest</p>
                    </div>
                    <div>
                      <h6 className="countDetails">12</h6>
                      <p className="text-muted mb-1 breakTitle">Meal</p>
                    </div>
                    <div>
                      <h6 className="countDetails">10</h6>
                      <p className="text-muted mb-1 breakTitle">Accepted</p>
                    </div>
                    <div>
                      <h6 className="countDetails">12</h6>
                      <p className="text-muted mb-1 breakTitle">Rejected</p>
                    </div>
                    <div>
                      <h6 className="countDetails">15</h6>
                      <p className="text-muted mb-1 breakTitle">Expired</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
              <div className="top-section text-center rounded bg-light">
                  <h5 className=" text-danger breakMainTitle">Violation (25)</h5>
                  <div className="d-flex justify-content-around mt-2">
                    <div>
                      <h6 className="text-danger countDetails">10</h6>
                      <p className="text-muted mb-1 breakTitle">Open</p>
                    </div>
                    <div>
                      <h6 className="countDetails">15</h6>
                      <p className="text-muted mb-1 breakTitle">Approved</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Data List Table with Scroll */}
            {/* <div className="table-wrapper">
              <table className="table table-bordered shadow-sm">
                <thead className="table-light">
                  <tr>
                    <th style={{ width: "5%" }}>
                      <input
                        type="checkbox"
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th style={{ width: "15%" }}>Name</th>
                    <th style={{ width: "10%" }}>Date</th>
                    <th style={{ width: "10%" }}>Type</th>
                    <th style={{ width: "15%" }}>Location</th>
                    <th style={{ width: "10%" }}>Status</th>
                    <th style={{ width: "10%" }}>Violation</th>
                    <th style={{ width: "10%" }}>Department</th>
                    <th style={{ width: "15%" }}>Manager</th>
                  </tr>
                </thead>
                <tbody>
                  {mockData.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(item.id)}
                          onChange={() => handleRowSelect(item.id)}
                        />
                      </td>
                      <td>{item.name}</td>
                      <td>{item.date}</td>
                      <td>{item.type}</td>
                      <td>{item.location}</td>
                      <td>{item.status}</td>
                      <td>{item.violation}</td>
                      <td>{item.department}</td>
                      <td>{item.manager}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
